import './css/App.css';
import React, {useEffect, useRef, useState} from "react";
import {loadingAtom} from "./atoms";
import {useRecoilState} from 'recoil';
import Loading from "./core/Loading";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {getCompanyByUserUUID, userUUIDExists} from "./utils/requestUtils";
import homeIcon from './img/icons8-home-24.png';


function Menu({children}) {

    const [currentLoading, setCurrentLoading] = useRecoilState(loadingAtom);

    const [activeMenu, setActiveMenu] = useState(null);
    const navigate = useNavigate();

    const checkIfUserIsLogged = () => {
        userUUIDExists().catch(r => {
            if (r.statusCode === 404) {
                navigate('/login');
            }
        });
    }

    const handleMenuClick = (menu) => {
        checkIfUserIsLogged();
        if (activeMenu === menu && isMenuVisible) {
            setMenuVisible(false);
            setActiveMenu(null);
        } else {
            setActiveMenu(menu);
            if (menu === 'Cadastrar' || menu === 'Consultar' || menu === 'Cliente' || menu === 'Produto' || menu === 'Pedido' || menu === 'Financeiro' || menu === 'Entrada' || menu === 'Mesa') {
                setMenuVisible(true);
            } else {
                setMenuVisible(false);
            }
        }
    };


    const [isMenuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);


    const [companyName, setCompanyName] = useState(null);
    useEffect(() => {

        async function fetchData() {
            try {
                const result = await getCompanyByUserUUID();
                setCompanyName(result.name);
            } catch (error) {
                console.error('Erro ao buscar os dados:', error);
            }
        }

        fetchData();

        const handleOutsideClick = (event) => {
            // Se o clique não foi dentro do menu, oculta o menu
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false);
            }
        };

        // Adiciona um ouvinte de evento global para cliques fora do menu
        document.addEventListener('click', handleOutsideClick);

        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []); // Executa apenas uma vez durante a montagem do componente

    const toggleMenu = () => {
        setMenuVisible(!isMenuVisible);
    };

    function handleExitClickButton() {
        Cookies.remove('userUUID');
        Cookies.remove('user');
        localStorage.removeItem('products');
        localStorage.removeItem('customers');
        localStorage.removeItem('tables');
        localStorage.removeItem('sale');
        navigate('/login');
    }

    const userCookies = Cookies.get('user');
    let isUserAdmin = false;
    if (userCookies) {
        const user = JSON.parse(userCookies);
        isUserAdmin = user.email === 'renanedpedroso@gmail.com';
    }

    return (
        <div>
            {companyName}
            {currentLoading && (<Loading/>)}
            <div ref={menuRef} style={{
                zIndex: 1000,
                width: '100%',
                backgroundColor: "#333",
                display: "flex",
                flexWrap: "wrap",
                minHeight: "50px",
                alignItems: "center"
            }}>
                <div>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            setMenuVisible(false);
                            navigate('/app');
                        }}
                        className={'menu-button ' + (activeMenu === 'Inicio' ? 'active' : '')}
                    >
                        <img src={homeIcon} alt="Home" style={{width: '20px', height: '20px'}}/>
                    </div>
                </div>
                <div>

                    {isUserAdmin && <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Cliente');
                        }}
                        className={'menu-button ' + (activeMenu === 'Cliente' ? 'active' : '')}
                    >
                        Cliente
                    </div>
                    }
                    {isMenuVisible && activeMenu === 'Cliente' && (
                        <ul className="submenu">

                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/customerlist');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Cliente' ? 'active' : '')}
                                >
                                    Consultar Cliente
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/customer');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Cliente' ? 'active' : '')}
                                >
                                    Cadastrar Cliente
                                </div>
                            </li>
                        </ul>
                    )}
                </div>
                <div>
                    {isUserAdmin && <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Mesa');
                        }}
                        className={'menu-button ' + (activeMenu === 'Mesa' ? 'active' : '')}
                    >
                        Mesa
                    </div>
                    }
                    {isMenuVisible && activeMenu === 'Mesa' && (
                        <ul className="submenu">

                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/tablelist');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Mesa' ? 'active' : '')}
                                >
                                    Consultar Mesa
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/table');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Mesa' ? 'active' : '')}
                                >
                                    Cadastrar Mesa
                                </div>
                            </li>
                        </ul>
                    )}
                </div>
                <div>
                    {isUserAdmin && <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Produto');
                        }}
                        className={'menu-button ' + (activeMenu === 'Produto' ? 'active' : '')}
                    >
                        Produto
                    </div>
                    }
                    {isMenuVisible && activeMenu === 'Produto' && (
                        <ul className="submenu">

                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/productlist');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Produto' ? 'active' : '')}
                                >
                                    Consultar Produto
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/product');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Produto' ? 'active' : '')}
                                >
                                    Cadastrar Produto
                                </div>
                            </li>
                        </ul>
                    )}
                </div>
                <div>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Pedido');
                        }}
                        className={'menu-button ' + (activeMenu === 'Pedido' ? 'active' : '')}
                    >
                        Pedido
                    </div>
                    {isMenuVisible && activeMenu === 'Pedido' && (
                        <ul className="submenu">

                            <li>
                                <div
                                    onClick={() => {
                                        localStorage.removeItem('sale');
                                        navigate('/sale/table');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Pedido' ? 'active' : '')}
                                >
                                    Pedido Mesa
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => {
                                        localStorage.removeItem('sale');
                                        navigate('/sale/items');
                                        window.location.reload();
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Pedido' ? 'active' : '')}
                                >
                                    Pedido Balcão
                                </div>
                            </li>

                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/sale/pending');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Pedido' ? 'active' : '')}
                                >
                                    Fechar Comanda
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/sale/print');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Pedido' ? 'active' : '')}
                                >
                                    Reimprimir
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/sale/sales');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Pedido' ? 'active' : '')}
                                >
                                    Consultar Pedidos
                                </div>
                            </li>

                            <li>
                                <div
                                    onClick={() => {
                                        navigate('/sale/sales_items');
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Pedido' ? 'active' : '')}
                                >
                                    Produtos Vendidos
                                </div>
                            </li>
                        </ul>
                    )}
                </div>
                <div>
                    <div
                        onClick={() => {
                            toggleMenu();
                            handleExitClickButton();
                        }}
                        className="menu-button"
                    >
                        Sair
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
}

export default Menu;
