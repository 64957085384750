import React from 'react';
import {useNavigate} from "react-router-dom";

const Shortcuts = () => {
    const navigate = useNavigate();

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: '100%',
            justifyContent: "center",
            alignItems: "center"
        }}>
            <button className={"shorcut-button"} onClick={() => {
                localStorage.removeItem('sale');
                navigate('/sale/table');
            }}>Pedido Mesa
            </button>
            <button className={"shorcut-button"} onClick={() => {
                localStorage.removeItem('sale');
                navigate('/sale/items');
            }}>Pedido Balcão
            </button>
            <button className={"shorcut-button"} onClick={() => {
                localStorage.removeItem('sale');
                navigate('/sale/pending');
            }}>Fechar comanda
            </button>
            <button className={"shorcut-button"}  style={{backgroundColor:"#aaa"}} onClick={() => {
                navigate('/sale/print');
            }}>Reimprimir
            </button>
            <button className={"shorcut-button"} style={{backgroundColor:"#aaa"}} onClick={() => {
                navigate('/sale/sales');
            }}>Consultar Pedidos
            </button>
        </div>
    );
};

export default Shortcuts;
