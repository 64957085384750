import React from 'react';
import {BrowserRouter, Route, Router, Routes} from "react-router-dom";

import {useRecoilState} from "recoil";
import Login from "./Login";
import Loading from "./core/Loading";
import {loadingAtom} from "./atoms";
import Menu from "./Menu";
import Config from "./core/config";
import CustomerAutoFillingCentralizedDiv from "./customerAutoFilling/CustomerAutoFillingCentralizedDiv";
import CustomerAutoFilling_Name from "./customerAutoFilling/CustomerAutoFilling_Name";
import CustomerAutoFilling_Documents from "./customerAutoFilling/CustomerAutoFilling_Documents";
import CustomerAutoFilling_Address from "./customerAutoFilling/CustomerAutoFilling_Address";
import CustomerAutoFilling_Contact from "./customerAutoFilling/CustomerAutoFilling_Contact";
import CustomerAutoFilling_Kids from "./customerAutoFilling/CustomerAutoFilling_Kids";
import CustomerAutoFilling_About from "./customerAutoFilling/CustomerAutoFilling_About";
import CustomerAutoFilling_End from "./customerAutoFilling/CustomerAutoFilling_End";
import CustomerAutoFilling_InvalidLink from "./customerAutoFilling/CustomerAutoFilling_InvalidLink";
import CustomerManager from "./components/customer/CustomerManager";
import CentralizedView from "./components/common/CentralizedView";
import CustomerList from "./components/customer/CustomerList";
import ProductManager from "./components/product/ProductManager";
import ProductList from "./components/product/ProductList";
import SaleManagerItems from "./components/sale/SaleManagerItems";
import SaleManagerCustomer from "./components/sale/SaleManagerCustomer";
import TableManager from "./components/table/TableManager";
import TableList from "./components/table/TableList";
import SaleManagerTable from "./components/sale/SaleManagerTable";
import CustomerPendingSaleList from "./components/sale/CustomerPendingSaleList";
import FinishSaleItems from "./components/sale/FinishSaleItems";
import Shorcuts from "./components/common/Shorcuts";
import SaleManagerConfirmItems from "./components/sale/SaleManagerConfirmItems";
import SalesPrintedToPrintAgain from "./components/sale/SalesPrintedToPrintAgain";
import SalesList from "./components/sale/SalesList";
import SalesListItems from "./components/sale/SalesListItems";

const AppRouter = (props) => {

    const {customerKeyValid} = props;
    const [currentLoading] = useRecoilState(loadingAtom);

    return (
        <BrowserRouter>
            {currentLoading && (<Loading/>)}
            <Routes>
                <Route path="/app"
                       element={<Menu><CentralizedView><Shorcuts/></CentralizedView></Menu>}/>
                <Route path="/login"
                       element={<Login/>}/>
                <Route path="/customer"
                       element={<Menu><CentralizedView><CustomerManager/></CentralizedView></Menu>}/>
                <Route path="/customerlist"
                       element={<Menu><CentralizedView><CustomerList/></CentralizedView></Menu>}/>
                <Route path="/table"
                       element={<Menu><CentralizedView><TableManager/></CentralizedView></Menu>}/>
                <Route path="/tablelist"
                       element={<Menu><CentralizedView><TableList/></CentralizedView></Menu>}/>
                <Route path="/product"
                       element={<Menu><CentralizedView><ProductManager/></CentralizedView></Menu>}/>
                <Route path="/productlist"
                       element={<Menu><CentralizedView><ProductList/></CentralizedView></Menu>}/>
                <Route path="/sale/table"
                       element={<Menu><CentralizedView><SaleManagerTable/></CentralizedView></Menu>}/>
                <Route path="/sale/customer"
                       element={<Menu><CentralizedView><SaleManagerCustomer/></CentralizedView></Menu>}/>
                <Route path="/sale/items"
                       element={<Menu><CentralizedView><SaleManagerItems/></CentralizedView></Menu>}/>
                <Route path="/sale/confirm-items"
                       element={<Menu><CentralizedView><SaleManagerConfirmItems/></CentralizedView></Menu>}/>
                <Route path="/sale/print"
                       element={<Menu><CentralizedView><SalesPrintedToPrintAgain/></CentralizedView></Menu>}/>
                <Route path="/sale/pending"
                       element={<Menu><CentralizedView><CustomerPendingSaleList/></CentralizedView></Menu>}/>

                <Route path="/sale/finishitems/:customerIdsParam"
                       element={<Menu><CentralizedView><FinishSaleItems/></CentralizedView></Menu>}/>

                <Route path="/sale/sales"
                       element={<Menu><CentralizedView><SalesList/></CentralizedView></Menu>}/>
                <Route path="/sale/sales_items"
                       element={<Menu><CentralizedView><SalesListItems/></CentralizedView></Menu>}/>
                
                <Route path="/autocadastro/:customerKey"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Name customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/documentos"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Documents customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/endereco"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Address customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/contato"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Contact customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/crianca/:kidIndex"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Kids customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/sobre"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_About customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/fim"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_End customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/linkInvalido" element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_InvalidLink/></CustomerAutoFillingCentralizedDiv>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;

