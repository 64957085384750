export const estadosBrasileiros = [
    {sigla: 'AC', nome: 'Acre'},
    {sigla: 'AL', nome: 'Alagoas'},
    {sigla: 'AP', nome: 'Amapá'},
    {sigla: 'AM', nome: 'Amazonas'},
    {sigla: 'BA', nome: 'Bahia'},
    {sigla: 'CE', nome: 'Ceará'},
    {sigla: 'DF', nome: 'Distrito Federal'},
    {sigla: 'ES', nome: 'Espírito Santo'},
    {sigla: 'GO', nome: 'Goiás'},
    {sigla: 'MA', nome: 'Maranhão'},
    {sigla: 'MT', nome: 'Mato Grosso'},
    {sigla: 'MS', nome: 'Mato Grosso do Sul'},
    {sigla: 'MG', nome: 'Minas Gerais'},
    {sigla: 'PA', nome: 'Pará'},
    {sigla: 'PB', nome: 'Paraíba'},
    {sigla: 'PR', nome: 'Paraná'},
    {sigla: 'PE', nome: 'Pernambuco'},
    {sigla: 'PI', nome: 'Piauí'},
    {sigla: 'RJ', nome: 'Rio de Janeiro'},
    {sigla: 'RN', nome: 'Rio Grande do Norte'},
    {sigla: 'RS', nome: 'Rio Grande do Sul'},
    {sigla: 'RO', nome: 'Rondônia'},
    {sigla: 'RR', nome: 'Roraima'},
    {sigla: 'SC', nome: 'Santa Catarina'},
    {sigla: 'SP', nome: 'São Paulo'},
    {sigla: 'SE', nome: 'Sergipe'},
    {sigla: 'TO', nome: 'Tocantins'},
];


export function formatDateString(dateString) {

    const dateObj = new Date(dateString);

    const newDateString = dateObj.toISOString();

    const year = newDateString.slice(0, 4);
    const month = newDateString.slice(5, 7);
    const day = newDateString.slice(8, 10);

    return `${day}/${month}/${year}`;
}

export function formatDateTimeString(dateString) {

    const dateObj = new Date(dateString);

    dateObj.setHours(dateObj.getHours() - 3);


    const newDateString = dateObj.toISOString();

    const year = newDateString.slice(0, 4);
    const month = newDateString.slice(5, 7);
    const day = newDateString.slice(8, 10);

    const hours = ('0' + dateObj.getHours()).slice(-2);
    const minutes = ('0' + dateObj.getMinutes()).slice(-2);

    return `${day}/${month}/${year} ${hours}:${minutes}`;

}

export function formatDateToAmericanFormat(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    return year + '-' + month + '-' + day;
}

export function formatDateToString(date) {
    date = adjustDateToBrowserTimeZone(date);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return day + '/' + month + '/' + year;
}

export function calculateDaysDifference(date1, date2) {
    var date1Millis = date1.getTime();
    var date2Millis = date2.getTime();
    var differenceMillis = date2Millis - date1Millis;
    var differenceInDays = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
    return differenceInDays;
}

export function plusDays(date, days) {
    date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;

}

export function removeTimeFromDate(date) {
    date.setHours(0, 0, 0, 0);
    return date;
}

export function getDate(dateString) {

    const dateObj = new Date();
    dateObj.setUTCHours(0, 0, 0, 0);

    const newDateString = dateString;

    let year = newDateString.slice(0, 4);
    let month = Number(newDateString.slice(5, 7)) - 1;
    const day = newDateString.slice(8, 10);

    if (month === -1) {
        month = 12;
        year--;
    }

    dateObj.setFullYear(year, month, day);

    return dateObj;
}

export function adjustDateToBrowserTimeZone(date) {

    const offsetMinutes = new Date().getTimezoneOffset();

    date.setMinutes(date.getMinutes() + offsetMinutes);

    return date;
}

export function getOnlyDate(date) {
    date.setHours(0, 0, 0, 0);
    return date;
}